.document-editor {
  border: 1px solid var(--ck-color-base-border);
  border-radius: var(--ck-border-radius);
  max-height: 700px;

  /* This element is a flex container for easier rendering. */
  display: flex;
  flex-flow: column nowrap;
}

.document-editor__toolbar {
  /* Make sure the toolbar container is always above the editable. */
  z-index: 1;

  /* Create the illusion of the toolbar floating over the editable. */
  box-shadow: 0 0 5px hsla(0, 0%, 0%, .2);

  /* Use the CKEditor CSS variables to keep the UI consistent. */
  border-bottom: 1px solid var(--ck-color-toolbar-border);
}

/* Adjust the look of the toolbar inside of the container. */
.document-editor__toolbar .ck-toolbar {
  border: 0;
  border-radius: 0;
}

/* Make the editable container look like the inside of a native word processor app. */
.document-editor__editable-container {
  padding: calc(2 * var(--ck-spacing-large));
  background: var(--ck-color-base-foreground);

  /* Make it possible to scroll the "page" of the edited content. */
  overflow-y: scroll;
}

.document-editor__editable-container .document-editor__editable.ck-editor__editable {
  /* Set the dimensions of the "page". */
  width: 15.8cm;
  min-height: 21cm;

  /* Keep the "page" off the boundaries of the container. */
  padding: 1cm 2cm 2cm;

  border: 1px hsl(0, 0%, 82.7%) solid;
  border-radius: var(--ck-border-radius);
  background: white;

  /* The "page" should cast a slight shadow (3D illusion). */
  box-shadow: 0 0 5px hsla(0, 0%, 0%, .1);

  /* Center the "page". */
  margin: 0 auto;
}

/* Override the page's width in the "Examples" section which is wider. */
.main__content-wide .document-editor__editable-container .document-editor__editable.ck-editor__editable {
  width: 18cm;
}

/* Set the default font for the "page" of the content. */
.document-editor .ck-content,
.document-editor .ck-heading-dropdown .ck-list .ck-button__label {
  font: 14px "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin-bottom: 4px;
}

/* Set the list styles */
.document-editor .ck-content li {
  margin-bottom: 4px;
}
.document-editor .ck-content ol {
  margin-top: 4px;
}
.document-editor .ck-content ul {
  margin-top: 4px;
}

/* Adjust the headings dropdown to host some larger heading styles. */
.document-editor .ck-heading-dropdown .ck-list .ck-button__label {
  line-height: calc(1.7 * var(--ck-line-height-base) * var(--ck-font-size-base));
  min-width: 6em;
}

/* Scale down all heading previews because they are way too big to be presented in the UI.
Preserve the relative scale, though. */
.document-editor .ck-heading-dropdown .ck-list .ck-heading_heading1 .ck-button__label,
.document-editor .ck-heading-dropdown .ck-list .ck-heading_heading2 .ck-button__label {
  transform: scale(0.8);
  transform-origin: left;
}

/* Set the styles for "Heading 1". */
.document-editor .ck-content h2,
.document-editor .ck-heading-dropdown .ck-heading_heading1 .ck-button__label {
  font-weight: bold;
  margin-bottom: 6px;
  margin-top: 2px;
  font-size: 23px;
}

.document-editor .ck-content h2 {
  font-weight: bold;
  margin-bottom: 6px;
  margin-top: 2px;
  font-size: 23px;
}

/* Set the styles for "Heading 2". */
.document-editor .ck-content h3,
.document-editor .ck-heading-dropdown .ck-heading_heading2 .ck-button__label {
  font-weight: bold;
  margin-bottom: 6px;
  margin-top: 2px;
  font-size: 21px;
}

.document-editor .ck-content h3 {
  font-weight: bold;
  margin-bottom: 6px;
  margin-top: 2px;
  font-size: 21px;
}

/* Set the styles for "Heading 3". */
.document-editor .ck-content h4,
.document-editor .ck-heading-dropdown .ck-heading_heading3 .ck-button__label {
  font-weight: bold;
  margin-bottom: 6px;
  margin-top: 2px;
  font-size: 19px;
}

.document-editor .ck-content h4 {
  font-weight: bold;
  margin-bottom: 6px;
  margin-top: 2px;
  font-size: 19px;
}

/* Set the styles for "Paragraph". */
.document-editor .ck-content p,
.document-editor .ck-heading-dropdown .ck-heading_heading3 .ck-button__label {
  margin-bottom: 4px;
  font-size: 14px;
}

.document-editor .ck-content p {
  margin-bottom: 4px;
  font-size: 14px;
}

/* Set the styles for "Bold". */
.document-editor .ck-content strong {
  font-weight: bold;
}

/* Make the block quoted text serif with some additional spacing. */
.document-editor .ck-content blockquote {
  font-family: Georgia, serif;
  margin-left: 40px;
  margin-right: 10px;
  font-style: italic;
}

/* Some table cells have a lot content and some not. Align them vertically
to make reading easier. */
.document-editor .ck-content table td {
  vertical-align: middle;
}

@media only screen and (max-width: 960px) {
  /* Because on mobile 2cm paddings are to big. */
  .document-editor__editable-container .document-editor__editable.ck-editor__editable {
    padding: 1.5em;
  }
}

@media only screen and (max-width: 1200px) {
  .main__content-wide .document-editor__editable-container .document-editor__editable.ck-editor__editable {
    width: 100%;
  }
}

/* Style document editor a'ka Google Docs.*/
@media only screen and (min-width: 1360px) {
  .main .main__content.main__content-wide {
    padding-right: 0;
  }
}

@media only screen and (min-width: 1600px) {
  .main .main__content.main__content-wide {
    width: 24cm;
  }

  .main .main__content.main__content-wide .main__content-inner {
    width: auto;
    margin: 0 50px;
  }

  /* Keep "page" look based on viewport width. */
  .main__content-wide .document-editor__editable-container .document-editor__editable.ck-editor__editable {
    width: 60%;
  }
}

// Avoid focused

.ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-rounded-corners.ck-focused {
  border: 1px solid transparent;
  box-shadow: 0 0 10px rgba(0, 0, 0, .1);
}

.ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-rounded-corners {
  border: 1px solid transparent;
  box-shadow: 0 0 10px rgba(0, 0, 0, .1);
}