@import "christmas";


.navbar-vertical.navbar-expand-md {
  .navbar-nav {
    .nav-sublink {
      padding-left: 3rem;
    }
  }
}

.select-input-prefix__menu {
  z-index: 10 !important;
}

.form-control-alternative-error {
  border: 1pt solid var(--danger) !important;
  border-color: var(--danger) !important;
}

.avatar {
  background-color: #FFFFFF00;
}

.modal-xl {
    max-width: 1200px;
}

.modal-listados {
    max-height: 60vh;
    overflow-y: auto;
}