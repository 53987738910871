.rdtPicker {
  min-width: 300px;
}

.rdt {
  input[readonly] {
    background-color: $input-bg !important;
  }

  input[disabled] {
    background-color: $input-disabled-bg !important;
  }
}

.page-item.active .page-link {
  background-color: #fb6340;
  border-color: #fb6340;
}

.select-input {
  min-height: calc(2.75em + 7.5px);
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
  border: 0;
  transition: box-shadow .15s ease;
  border-radius: 0.375rem;
  position: relative;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
  font-size: 0.875rem;
  height: 100%;
  line-height: 1.5;
}

.select-input-prefix__control {
  min-height: calc(2.75em + 7.5px) !important;
  border: 0 !important;
  box-shadow: none !important;
  height: 100%;
}

.select-input-prefix__control--is-disabled {
  min-height: calc(2.75em + 7.5px) !important;
  background-color: $input-disabled-bg !important;
}

.select-input-prefix__single-value {
  color: $input-color !important;
}

.select-input-prefix__input {
  color: $input-color !important;
}

.select-input-prefix__placeholder {
  color: $input-placeholder-color !important;
}


.pulse {
  border-radius: 50%;
  transform: scale(1);
  animation: pulse 2s infinite;
}


@keyframes pulse {
  0% {
    transform: scale(0.90);
    //box-shadow: 0 0 0 0 rgba(255, 121, 63, 0.7);
  }

  70% {
    transform: scale(1);
    // box-shadow: 0 0 0 10px rgba(255, 121, 63, 0);
  }

  100% {
    transform: scale(0.90);
    // box-shadow: 0 0 0 0 rgba(255, 121, 63, 0);
  }
}


.highlight {
  transform: scale(1);
  animation: highlight 2s infinite;
}


@keyframes highlight {
  0% {
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0 rgba(255, 121, 63, 0.7);
  }

  70% {
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 10px rgba(255, 121, 63, 0);
  }

  100% {
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08), 0 0 0 0 rgba(255, 121, 63, 0);
  }
}

.custom-toggle input:checked + .custom-toggle-slider {
  border-color: $orange;
}

.custom-toggle input:checked + .custom-toggle-slider:before {
  background-color: $orange;
}

@media (min-width: 768px) {
  .sticky-bottom-version {
    position: sticky;
    position: -webkit-sticky;
    bottom: 0;
  }
}
